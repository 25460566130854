<template>
    <div class="articles color-secondary c w-m mv-xxl">
        <article v-for="(item, index) in articles" :key="'article' + item.id + '_' + index">
            <h2 class="color-primary">{{ item['title_' + lang] || item.name }}</h2>
            <div v-html="item['config_' + lang]" class="richtext" />

            <div>
                <a v-if="item.file" :href="$options.filters.cdnUrl(item.file)" class="button bg-bg mb-xl" download>
                    {{ $t('products.download') }}
                    <icon class="mdi mdi-attachment" />
                </a>
                <a v-if="item['file_' + lang]" :href="$options.filters.cdnUrl(item['file_' + lang])"
                    class="button bg-bg mb-xl" download>
                    {{ $t('products.download') }}
                    <icon class="mdi mdi-attachment" />
                </a>
            </div>
        </article>
    </div>
</template>

<script>
export default {
    name: 'Articles',
    props: {
        articles: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        lang() {
            return this.$store.getters['Language/current'];
        },
    },
    data() {
        return {
            blockSettings: {
                image: {
                    attrs: { class: 'w-100' },
                },
                embed: {
                    attrs: { class: 'w-100' },
                },
            },
        }
    }
};
</script>
